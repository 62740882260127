@import "./app/shared/styles/variables.scss";
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~saturn-datepicker/bundle.css";
@import "~angular-vertical-timeline/dist/vertical-timeline.css";

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: proximaNova;
  src: url(assets/fonts/ProximaNova-Regular.otf) format("opentype");
}

* {
  font-family: "proximaNova", sans-serif;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.02em;
}

html,
body {
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  background-color: $color-lighter;
  margin: 0;
  & h1 {
    font-size: 1.125em;
  }

  & div {
    font-size: 14px;
  }
}

.mat-drawer-content {
  overflow: hidden !important;
}

/* Validations*/

.mat-error {
  font-size: 11px !important;
}

/*Buttons*/

.btn {
  width: 8.57em;
  height: 2.57em;
}

.btn__primary {
  background-color: $color-blue;
  color: $color-clear;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
}

.btn__primary:disabled,
.btn__secondary:disabled {
  cursor: not-allowed !important;
}

.btn__primary:disabled {
  background: #bfd5e8 !important;
  color: $color-clear !important;
  border: 1px solid #bfd5e8 !important;
}

.btn__secondary-red:disabled,
.btn__secondary:disabled {
  background: #ffffff !important;
  color: #aaaaaa !important;
  border: 1px solid #ffffff !important;
}

.btn__secondary {
  border: 0.0714em solid $color-blue !important;
  box-sizing: border-box;
  border-radius: 0.285em;
  color: $color-blue;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
}

.btn__secondary-red {
  border: 0.0714em solid $color-red !important;
  box-sizing: border-box;
  border-radius: 0.285em;
  color: $color-red;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
}

.btn__link {
  color: $color-blue;
  cursor: pointer;
}

/*Angular Material form Inputs*/

.mat-select-value {
  color: $color-primary;
}

mat-form-field.mat-form-field.mat-form-field-appearance-outline
  > div.mat-form-field-wrapper
  > div.mat-form-field-flex
  > div.mat-form-field-infix {
  padding: 0.4em 0px;
}

mat-form-field.mat-form-field.mat-form-field-appearance-outline
  > div.mat-form-field-wrapper
  > div.mat-form-field-flex
  > div.mat-form-field-infix
  > span.mat-form-field-label-wrapper {
  top: -1.5em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
  transform: translateY(-1.1em) scale(0.75);
  width: 133.33333%;
}

.mat-form-field-appearance-outline
  .mat-form-field-outline
  .mat-form-field-outline-thick {
  color: $color-blue;
}

.mat-form-field-outline {
  background-color: white !important;
  border-radius: 5px;
  color: $color-blue;
  border-color: $color-blue;
}

input.mat-input-element {
  color: $color-blue;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $color-primary;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: $color-primary;
}
/* Angular Material Icons */
.mat-icon {
  color: $color-blue;
}

/*Angular Material Slide*/
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(133, 177, 216, 0.8);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $color-primary;
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: $color-primary;
}

/* Angular Material checkboxes */
::ng-deep .mat-checkbox .mat-checkbox-frame {
  border-color: $color-primary;
}

::ng-deep .mat-checkbox-checked .mat-checkbox-background {
  background-color: $color-primary !important;
}

::ng-deep .mat-accent .mat-ripple-element {
  background-color: $color-primary !important;
}

::ng-deep .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $color-primary !important;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: $color-primary !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $color-primary !important;
}
/* Client dialog panel*/
.client-dialog {
  margin: auto;
  top: 3.357em;
  position: absolute !important;
  width: 100%;
  height: 90%;
  & .mat-dialog-content {
    max-height: 100%;
  }
}

.pet-form-dialog {
  margin: auto;
  width: 70%;
  max-height: 80%;
}

/* Client unmerge dialog panel*/
.unmerge-dialog {
  position: absolute !important;
  min-width: 80%;
  min-height: 65%;
  & .mat-dialog-content {
    max-height: 100%;
  }
}

/* Family attributes*/
.family-attributes-dialog {
  position: absolute !important;
  min-width: 80%;
  min-height: 80%;
  & .mat-dialog-content {
    max-height: 100%;
  }
}

.action-dialog {
  min-width: 80%;
  max-height: 80%;
}
/* TAGS */

.tag-animal-type {
  color: $color-clear;
  width: 6.857em;
  height: 1.285em;
  text-align: center;
  font-size: 11px;
  padding-top: 0.357em;
  border-radius: 0.181em;
}

.tag-dog {
  background-color: $color-dog;
}

.tag-cat {
  background-color: $color-cat;
}

.tag-bird {
  background-color: $color-bird;
}

.tag-fish {
  background-color: $color-fish;
}

.tag-rodent {
  background-color: $color-rodent;
}

.tag-other-animal {
  background-color: $color-other-animal;
}

.tag-pet-breed {
  background-color: $color-blue;
  color: $color-clear;
  width: 6.857em;
  height: 1.285em;
  text-align: center;
  font-size: 11px;
  padding-top: 0.357em;
  border-radius: 0.181em;
  margin-left: 1.272em;
}

//snack bar
.mat-simple-snackbar .ng-star-inserted {
  color: $color-primary;
}

.mat-snack-bar-container {
  font-family: Proxima Nova;
  font-size: 14px;
  line-height: 130%;
  color: #000000;
  letter-spacing: 0.02em;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 2.14em !important;
  background-color: $color-clear;
}

.ng-star-inserted.current {
  border-radius: 50%;
}

// .pagination-previous {
//   content: 'asdasd';
// }

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $color-blue;
}

.mat-progress-bar .mat-progress-bar-buffer {
  background-color: $color-blue-light;
}

.mat-progress-bar .mat-progress-bar-fill::after {
  background-color: $color-blue !important;
}

//Forms//
.form {
  .mat-form-field {
    margin-right: 10px;
  }
  .full-width {
    width: 100%;
  }
}
//End Forms//

// inicio stepper

.stepper-modal {
  max-width: 767px;
}

/* input ancho completo */
.form-w-100,
.form-w-50,
.form-w-33 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.form-w-100 {
  width: calc(100% - 16px);
}

.form-w-50 {
  width: calc(50% - 16px);
}

.form-w-33 {
  width: calc(33% - 14px);
}

// mostrar icono arriba de texto
.mat-step-header {
  flex-direction: column;
}

// margenes para icono
.mat-horizontal-stepper-header .mat-step-icon {
  margin-right: 0 !important;
  margin-bottom: 4px;
}

.mat-step-header {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.mat-step-icon {
  width: 50px !important;
  height: 50px !important;
}

.mat-step-icon .mat-icon {
  font-size: 25px !important;
  width: 25px !important;
  height: 25px !important;
}

.mat-step-label {
  text-align: center !important;
}

.stepper-buttons-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.stepper-buttons-container button {
  margin-left: 8px;
}

.stepper-buttons-container button:first-child {
  margin-left: 0;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background: $color-blue !important;
  color: white !important;
}

.mat-step-icon-content .mat-icon,
.mat-stepper .mat-icon {
  color: white !important;
}

.mat-horizontal-stepper-content {
  position: relative;
}

.mat-dialog-container .mat-horizontal-content-container {
  padding-bottom: 0px;
}
// final stepper

// INICIO component list
.component_list {
  .list__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & h1 {
      flex: 1 auto;
    }

    & .mat-form-field {
      top: 0.65em;
      width: 18em;
    }

    & ::ng-deep.mat-form-field-outline {
      background-color: $color-grey-light;
    }

    & .remove-search {
      cursor: pointer;
    }
  }

  .list__container {
    display: flex;
    flex-wrap: nowrap;
  }

  .list__body,
  .list__grid {
    width: 100%;
  }

  .list__grid .mat-table {
    //margin-top: 0.714em;
    border-radius: 0.857em 0.857em 0 0;
  }

  .mat-table .action-header,
  .mat-table .action-row {
    width: 50px;
  }

  .list__header {
    width: 100%;
    margin-top: 3.2em;
    & h1 {
      flex: 1 auto;
    }
    & .mat-form-field {
      top: 0.65em;
    }
  }
}
// FIN component_list

//Drag and drop//
.ngx-file-drop__drop-zone--over,
.ngx-file-drop__drop-zone--over[_ngcontent-aqt-c28] {
  background-color: transparent !important;
}

// TABLE //
.mat-table {
  // padding-bottom: 20px;
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type,
  th.mat-header-cell:first-of-type {
    padding-left: 24px !important;
  }

@media screen and (min-width:992px) {
  td.number {
    text-align: right;
    padding-right: 70px !important;
  }
}

  td.negative {
    background-color: #fac5c5;
  }

  td.total-1 {
    background-color: #829fd9;
  }

  td.total-2 {
    background-color: #5c73f2;
  }

  td.total-3 {
    background-color: #3e38f2;
  }

  th {
    white-space: normal !important;
    min-width: 30px;
  }

  td,
  th {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  td a {
    text-decoration: none;
    font-weight: 400;
    color: #0058a4;
  }

  td.small {
    font-size: 12px;
  }

  th.width-1 {
    min-width: 150px;
  }

  th.width-2 {
    min-width: 200px;
  }

  th.width-3 {
    min-width: 250px;
  }
}

.client-list__grid {
  min-height: 200px;
  position: relative;
}

.columnTitleMore {
  text-align: center;
}

.iconCol{
  flex: 0 0 100px;
}

.channel-ico{
  width: 30px;
}

.noBordes {
  border:none;
  background:none;
}

.mat-table-sticky-border-elem-right {
  border-left: 1px solid #e0e0e0;
}

.mat-table-sticky-border-elem-left {
  border-right: 1px solid #e0e0e0;
}

.more-btn{
  min-width:0px !important;
  padding: 0px !important;
}

.mat-table .action-header{
  min-width: 0px;
}

// END TABLE //

.rotate-4 {
  animation-name: rotar360;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-delay: 0;
  animation-timing-function: linear;
}

.titleLink {
  color: $color-blue;
  margin-left: 8px;
  cursor: pointer;
  display: inline-block;
}

.titleLink:hover {
  color: $color-blue-over;
  text-decoration: underline;
}

@media screen and (max-width: 1850px) {
  .layout__container {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  mat-footer-row,
  mat-header-row,
  mat-row,
  td.mat-cell,
  td.mat-footer-cell,
  th.mat-header-cell {
    // font-size:10px !important;
  }
}

@media screen and (max-width:991px) {
  .layout__container {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }
}

.number-user__selector mat-label,
.number-user__selector mat-select {
}

.number-user__selector mat-label {
  white-space: nowrap;
}

.number-user__selector mat-select.SelectsFixedWidth {
  min-width: 80px;
  text-align: center;
}

.noMostrarDisabled {
  transition: all 0.15s ease-in-out;
}

.noMostrarDisabled[disabled] {
  // visibility:hidden;
  // display:none;
  opacity: 0.75;
  filter: saturate(0);
  pointer-events: none;
  transition: all 0.15s ease-in-out;
}

.destacadoBlack {
  font-weight: 600;
  color: black;
}

/* elementos clickeables que necesitan la manito en el cursor */
.estrellaHighlight,
.cursorPointer {
  cursor: pointer;
}

/* INICIO badges custom, que se muestran independiemente */

.customBadge {
  color: white;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  padding: 3px;
  border-radius: 50%;
  display: inline-flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  cursor: default;
}

/* FINAL badges custom, que se muestran independiemente */

/* INICIO variante boton small */

.btn_small {
  padding: 0 8px 3px 2px !important;
  cursor: pointer;
}

.btn_small,
.btn_small .mat-button-wrapper {
  font-size: 11px;
}

.btn_small .mat-icon {
  font-size: 18px;
  transform: translateY(4px);
}

/* FINAL variante boton small */

.btn__secondary {
  background-color: white;
}

.btn__secondary:hover {
  background-color: #f5f5f5;
}

/* INICIO chips pequeños */

.smallChip {
  font-size: 12px !important;
  padding: 5px 6px 4px 6px !important;
  max-height: auto !important;
  min-height: 25px !important;
  border-radius: 5px !important;
  margin-right: 5px;
}

/* FINAL chips pequeños */

/* INICIO CARDS INFORMATIVAS */

.cardsHolder,
.cardsContainer,
.cardsIcon,
.cardsText,
.cardsData {
  display: flex;
}

@media screen and (max-width:991px) {
  .cardsHolder {
    display:none !important;
  }
}

.cardsHolder {
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.cardsContainer {
  border: solid 1px #dadada;
  border-radius: 5px;
  margin: 4px;
  cursor: pointer;
  color: white;
  transition: all 0.15s ease-in-out;
  background: grey;
}

.cardsIcon,
.cardsText,
.cardsData {
  padding: 15px;
  border-color: $color-grey-light;
  border-width: 1px;
  align-items: center;
}

.cardsIcon {
  padding-right: 0;
}

.cardsIcon img {
  width: auto;
  max-height: 70%;
  transition: all 0.15s ease-in-out;
}

.mat-dialog-container .cardsHolder .cardsIcon,
.mat-dialog-container .cardsHolder .cardsText,
.mat-dialog-container .cardsHolder .cardsData {
  padding: 10px;
}

.cardsData {
  font-size: 1.5rem;
  background: rgba(255, 255, 255, 0.15);
  justify-content: center;
  min-width: 40px;
}

.badgeBgAlert,
.cardBgAlert {
  background: #ea8c00;
}

.cardBgDanger {
  background: #d83040;
}

.cardBgOk {
  background: #3aab00;
}

.cardBgInfo {
  background: #2199e8;
}

.cardBgViolet {
  background: #3f51b5;
}

.cardBgDone {
  background: #6c757d;
}

.cardIcon {
  color: white;
}

/* inicio cards que ocupan todo el espacio */
/* estos cards toman todo el ancho del contenedor, para no dejar espacios vacios, se agrega al contenedor .cardsContainer */

.cardsContainerFullWidth {
  flex-grow: 2;
  justify-content: space-between;
  // flex-basis:0;
}

/* final cards que ocupan todo el espacio */

/* inicio cards sin data "cardData" */

.cardsContainerNoData {
  justify-content: flex-start;
  cursor: default;
}

.cardsContainerNoData .cardsText {
  flex-direction: column;
  align-items: flex-start;
}

.cardTextTitle {
  font-size: 11px;
  color: $color-lighter;
}

.cardTextData {
  font-size: 16px;
}

/* final cards sin data "cardData" */

/* inicio cards clickeables */

.cardClickeable {
  cursor: pointer;
}

.cardClickeable:hover {
  filter: brightness(1.05);
}

.cardClickeable:hover .cardsIcon img {
  transform: scale(1.1);
}

/* final cards clickeables */

.cardsGenericTheme {
  background: white;
  color: black;
  border: none;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.cardsGenericTheme .cardTextTitle {
  color: grey;
}

/* fix para que los margenes sean iguales cuando los cards ocupan 100% de ancho */
.cardsFixMarginsContainer {
  margin-right: -10px;
}

.cardsFixMargins {
  margin: 10px 10px 0 0;
}

/* FINAL CARDS INFORMATIVAS */

/* INICIO LAYOUT FIXED */

.layoutFixedTop {
}

.layoutFixedBottom {
  display: flex;
  justify-content: space-between;
  //padding-bottom:10px;
}

.layoutFixedBottomLeft,
.layoutFixedBottomMiddle,
.layoutFixedBottomRight {
  flex-basis: 100%;
  padding: 0.1rem;
}

.layoutFixedBottomLeft {
  margin-right: 16px;
  // max-width: 600px;
}

// .layoutFixedBottomMiddle
.layoutFixedBottom {
  max-height: calc(100vh - 270px);
  overflow-y: auto;
}

.layoutFixedBottomRight {
}

@media screen and (max-width: 1100px) {
  .layoutFixedBottom {
    flex-direction: column;
  }
  .layoutFixedBottomLeft {
    margin-right: 0;
    max-width: 100%;
    margin-bottom: 8px;
  }
  // .layoutFixedBottomMiddle
  .layoutFixedBottom {
    max-height: 100%;
  }
}

/* FINAL LAYOUT FIXED */

/* INICIO presentacion de campos, no editables, solo texto */

.dataGroupContainer {
  margin: 0 25px 5px 25px;
  display: flex;
  flex-wrap: wrap;
}

.dataGroup {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding-right: 1rem;
  box-sizing: border-box;
}

.dataTag {
  font-size: 14px;
  margin-bottom: 2px;
  color: grey;
}

.dataValue {
  font-size: 16px;
}

.dataValueLink {
  text-decoration: none;
  color: $color-blue;
  font-size: 12px;
}

.dataValueLink:hover {
  text-decoration: underline;
}

.dataValueLink mat-icon {
  transform: scale(0.75) translate(-3px, -2px);
  vertical-align: top;
  color: grey;
}

.dataValueLink:hover mat-icon {
  color: inherit;
}

/* FINAL presentacion de campos, no editables, solo texto */

// links dentro de celdas de tablas, se quita subrayado y cambios de color
.cellLink-noStyle {
  text-decoration: none;
  color: $color-blue;
  font-weight: 600;
}
.cellLink-noStyle:hover {
  text-decoration: none;
}

/*
INICIO layout 1:
  - header con botones
  - tabla
*/

.headerWithActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //padding: 10px 0;

  background: #f7f7f7;
  padding: 5px 15px 5px 15px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 3px;
  border: solid 1px #e2e2e2;
}

.headerTitle {
  font-size: 17px;
  white-space:nowrap;
}

.headerInput {
  display:flex;
  width:100%;
  align-items:center;
  padding:0 10px 0 10px;
}

.headerActions {
  white-space:nowrap;
}

.headerSearchContainer {
  flex-basis: 100%;
  padding: 15px 15px 15px 100px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.headerSearchContainer mat-form-field {
  width:100%;
  max-width:500px;
}

.headerActions button {
  // margin-right: 8px;
}

.headerActions button:last-child {
  margin-right: 0;
}

.tablaScrollFullHeight {
  overflow: auto;
  max-height: calc(100vh - 200px) !important;
}

.headerMobileInline {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width:992px) {
  .headerWithActionsResponsive {
    flex-direction:column;
    padding:15px;
  }
  .headerTitle,
  .headerInput {
    width:100%;
  }

  .mobileAlignStart {
    align-self:flex-start;
  }

  .mobileAlignEnd {
    align-self:flex-end;
  }

  .headerActions {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    width:100%;
  }

  .headerSearchContainer {
    width:100%;
    padding:0;
  }

  .headerSearchContainer mat-form-field {
    max-width:100%;
  }
  

  .headerTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

}

/* FINAL layout 1 */

.mat-table {
  border-radius: 0 !important;
  margin-top: 0 !important;
}

/* INICIO layout para pantallas que contienen tabla de datos, botones y selector de sucursal en la parte superior */

.newLayoutContainer {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
}

.newLayoutSuperior {
}

.newLayoutCentro {
  flex-basis: auto;
  height: auto;
  overflow-x: auto;
}

.newLayoutCentro table {
  width: 100%;
}

.newLayoutInferior {
  // display:flex;
}

.newLayoutFilters {
  /* contenedor de filtros */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* FINAL layout para pantallas que contienen tabla de datos, botones y selector de sucursal en la parte superior */

.csv-upload-example {

  border: solid 1px #d8d8d8;
  border-radius: 0.6em;
  background: #f7f7f7;


  table {
    text-align: left;
    padding: 10px;
    width: 100%;
    border: solid 1px #d8d8d8;
    border-radius: 0.6em;
    background: #f7f7f7;
    font-style: italic;
    color: #4e4e4e;
    td,
    th {
      padding-right: 10px;
    }
    td::after,
    th::after {
      content: ";";
    }
  }
}

.loading-child-component {
  display: block;
  justify-content: center;
  position: absolute;
}
.child-card {
  height: 2px;
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}

span.pill-warn {
  background-color: #f44336;
  color: #fff;
  padding: 5px;
  line-height: 15px;
  height: fit-content;
  margin-left: 10px;
  margin-top: 8px;
  border-radius: 15px;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}


$base-shadow-card: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);



.mat-hint{
  font-size: 12px;
}


.scan-dialog-container .mat-dialog-container {
  outline: 2px solid greenyellow;
  padding: 0px !important;
  overflow: hidden;
}

@media screen and (max-width:991px) {
  .ocultarEnMobile {
      display:none;
      visibility:hidden;
  }
}



.data-valdiation-landing-corner-class .mat-dialog-container {
  border-radius: 20px !important;
}


.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-success {
  color: #0f5132;;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.safe-letter {
  color: #5ac73c;
}
.warning-letter {
  color: #bd9f32;
}
.danger-letter {
  color: #b82b35;
}