@charset "UTF-8";
/* Status */
/* Animals */
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~saturn-datepicker/bundle.css";
@import "~angular-vertical-timeline/dist/vertical-timeline.css";
.infoItemSubGrilla {
  display: flex;
  flex-wrap: wrap;
  transition: filter 0.15s ease-in-out;
  filter: brightness(0.9);
}

.infoItemSubGrilla:hover {
  cursor: pointer;
  filter: brightness(1);
}

/* INICIO helpers */
.w-100 {
  width: 100%;
}

.m-0 {
  margin: 0 !important;
}

.m-4 {
  margin: 4px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-12 {
  margin: 12px !important;
}

.m-16 {
  margin: 16px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-24 {
  margin: 24px !important;
}

.m-28 {
  margin: 28px !important;
}

.m-32 {
  margin: 32px !important;
}

.m-36 {
  margin: 36px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-44 {
  margin: 44px !important;
}

.m-48 {
  margin: 48px !important;
}

.m-52 {
  margin: 52px !important;
}

.m-56 {
  margin: 56px !important;
}

.m-60 {
  margin: 60px !important;
}

.m-64 {
  margin: 64px !important;
}

.m-68 {
  margin: 68px !important;
}

.m-72 {
  margin: 72px !important;
}

.m-76 {
  margin: 76px !important;
}

.m-80 {
  margin: 80px !important;
}

.m-84 {
  margin: 84px !important;
}

.m-88 {
  margin: 88px !important;
}

.m-92 {
  margin: 92px !important;
}

.m-96 {
  margin: 96px !important;
}

.m-100 {
  margin: 100px !important;
}

.m-104 {
  margin: 104px !important;
}

.m-108 {
  margin: 108px !important;
}

.m-112 {
  margin: 112px !important;
}

.m-116 {
  margin: 116px !important;
}

.m-120 {
  margin: 120px !important;
}

.m-124 {
  margin: 124px !important;
}

.m-128 {
  margin: 128px !important;
}

.m-132 {
  margin: 132px !important;
}

.m-136 {
  margin: 136px !important;
}

.m-140 {
  margin: 140px !important;
}

.m-144 {
  margin: 144px !important;
}

.m-148 {
  margin: 148px !important;
}

.m-152 {
  margin: 152px !important;
}

.m-156 {
  margin: 156px !important;
}

.m-160 {
  margin: 160px !important;
}

.m-164 {
  margin: 164px !important;
}

.m-168 {
  margin: 168px !important;
}

.m-172 {
  margin: 172px !important;
}

.m-176 {
  margin: 176px !important;
}

.m-180 {
  margin: 180px !important;
}

.m-184 {
  margin: 184px !important;
}

.m-188 {
  margin: 188px !important;
}

.m-192 {
  margin: 192px !important;
}

.m-196 {
  margin: 196px !important;
}

.m-200 {
  margin: 200px !important;
}

.m-204 {
  margin: 204px !important;
}

.m-208 {
  margin: 208px !important;
}

.m-212 {
  margin: 212px !important;
}

.m-216 {
  margin: 216px !important;
}

.m-220 {
  margin: 220px !important;
}

.m-224 {
  margin: 224px !important;
}

.m-228 {
  margin: 228px !important;
}

.m-232 {
  margin: 232px !important;
}

.m-236 {
  margin: 236px !important;
}

.m-240 {
  margin: 240px !important;
}

.m-244 {
  margin: 244px !important;
}

.m-248 {
  margin: 248px !important;
}

.m-252 {
  margin: 252px !important;
}

.m-256 {
  margin: 256px !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mx-4 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.my-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.mx-8 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.mx-12 {
  margin-right: 12px !important;
  margin-left: 12px !important;
}

.my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.mx-16 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.mx-20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mx-24 {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

.my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.mx-28 {
  margin-right: 28px !important;
  margin-left: 28px !important;
}

.my-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.mx-32 {
  margin-right: 32px !important;
  margin-left: 32px !important;
}

.my-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.mx-36 {
  margin-right: 36px !important;
  margin-left: 36px !important;
}

.my-36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.mx-40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mx-44 {
  margin-right: 44px !important;
  margin-left: 44px !important;
}

.my-44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.mx-48 {
  margin-right: 48px !important;
  margin-left: 48px !important;
}

.my-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.mx-52 {
  margin-right: 52px !important;
  margin-left: 52px !important;
}

.my-52 {
  margin-top: 52px !important;
  margin-bottom: 52px !important;
}

.mx-56 {
  margin-right: 56px !important;
  margin-left: 56px !important;
}

.my-56 {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}

.mx-60 {
  margin-right: 60px !important;
  margin-left: 60px !important;
}

.my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.mx-64 {
  margin-right: 64px !important;
  margin-left: 64px !important;
}

.my-64 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.mx-68 {
  margin-right: 68px !important;
  margin-left: 68px !important;
}

.my-68 {
  margin-top: 68px !important;
  margin-bottom: 68px !important;
}

.mx-72 {
  margin-right: 72px !important;
  margin-left: 72px !important;
}

.my-72 {
  margin-top: 72px !important;
  margin-bottom: 72px !important;
}

.mx-76 {
  margin-right: 76px !important;
  margin-left: 76px !important;
}

.my-76 {
  margin-top: 76px !important;
  margin-bottom: 76px !important;
}

.mx-80 {
  margin-right: 80px !important;
  margin-left: 80px !important;
}

.my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.mx-84 {
  margin-right: 84px !important;
  margin-left: 84px !important;
}

.my-84 {
  margin-top: 84px !important;
  margin-bottom: 84px !important;
}

.mx-88 {
  margin-right: 88px !important;
  margin-left: 88px !important;
}

.my-88 {
  margin-top: 88px !important;
  margin-bottom: 88px !important;
}

.mx-92 {
  margin-right: 92px !important;
  margin-left: 92px !important;
}

.my-92 {
  margin-top: 92px !important;
  margin-bottom: 92px !important;
}

.mx-96 {
  margin-right: 96px !important;
  margin-left: 96px !important;
}

.my-96 {
  margin-top: 96px !important;
  margin-bottom: 96px !important;
}

.mx-100 {
  margin-right: 100px !important;
  margin-left: 100px !important;
}

.my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.mx-104 {
  margin-right: 104px !important;
  margin-left: 104px !important;
}

.my-104 {
  margin-top: 104px !important;
  margin-bottom: 104px !important;
}

.mx-108 {
  margin-right: 108px !important;
  margin-left: 108px !important;
}

.my-108 {
  margin-top: 108px !important;
  margin-bottom: 108px !important;
}

.mx-112 {
  margin-right: 112px !important;
  margin-left: 112px !important;
}

.my-112 {
  margin-top: 112px !important;
  margin-bottom: 112px !important;
}

.mx-116 {
  margin-right: 116px !important;
  margin-left: 116px !important;
}

.my-116 {
  margin-top: 116px !important;
  margin-bottom: 116px !important;
}

.mx-120 {
  margin-right: 120px !important;
  margin-left: 120px !important;
}

.my-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.mx-124 {
  margin-right: 124px !important;
  margin-left: 124px !important;
}

.my-124 {
  margin-top: 124px !important;
  margin-bottom: 124px !important;
}

.mx-128 {
  margin-right: 128px !important;
  margin-left: 128px !important;
}

.my-128 {
  margin-top: 128px !important;
  margin-bottom: 128px !important;
}

.mx-132 {
  margin-right: 132px !important;
  margin-left: 132px !important;
}

.my-132 {
  margin-top: 132px !important;
  margin-bottom: 132px !important;
}

.mx-136 {
  margin-right: 136px !important;
  margin-left: 136px !important;
}

.my-136 {
  margin-top: 136px !important;
  margin-bottom: 136px !important;
}

.mx-140 {
  margin-right: 140px !important;
  margin-left: 140px !important;
}

.my-140 {
  margin-top: 140px !important;
  margin-bottom: 140px !important;
}

.mx-144 {
  margin-right: 144px !important;
  margin-left: 144px !important;
}

.my-144 {
  margin-top: 144px !important;
  margin-bottom: 144px !important;
}

.mx-148 {
  margin-right: 148px !important;
  margin-left: 148px !important;
}

.my-148 {
  margin-top: 148px !important;
  margin-bottom: 148px !important;
}

.mx-152 {
  margin-right: 152px !important;
  margin-left: 152px !important;
}

.my-152 {
  margin-top: 152px !important;
  margin-bottom: 152px !important;
}

.mx-156 {
  margin-right: 156px !important;
  margin-left: 156px !important;
}

.my-156 {
  margin-top: 156px !important;
  margin-bottom: 156px !important;
}

.mx-160 {
  margin-right: 160px !important;
  margin-left: 160px !important;
}

.my-160 {
  margin-top: 160px !important;
  margin-bottom: 160px !important;
}

.mx-164 {
  margin-right: 164px !important;
  margin-left: 164px !important;
}

.my-164 {
  margin-top: 164px !important;
  margin-bottom: 164px !important;
}

.mx-168 {
  margin-right: 168px !important;
  margin-left: 168px !important;
}

.my-168 {
  margin-top: 168px !important;
  margin-bottom: 168px !important;
}

.mx-172 {
  margin-right: 172px !important;
  margin-left: 172px !important;
}

.my-172 {
  margin-top: 172px !important;
  margin-bottom: 172px !important;
}

.mx-176 {
  margin-right: 176px !important;
  margin-left: 176px !important;
}

.my-176 {
  margin-top: 176px !important;
  margin-bottom: 176px !important;
}

.mx-180 {
  margin-right: 180px !important;
  margin-left: 180px !important;
}

.my-180 {
  margin-top: 180px !important;
  margin-bottom: 180px !important;
}

.mx-184 {
  margin-right: 184px !important;
  margin-left: 184px !important;
}

.my-184 {
  margin-top: 184px !important;
  margin-bottom: 184px !important;
}

.mx-188 {
  margin-right: 188px !important;
  margin-left: 188px !important;
}

.my-188 {
  margin-top: 188px !important;
  margin-bottom: 188px !important;
}

.mx-192 {
  margin-right: 192px !important;
  margin-left: 192px !important;
}

.my-192 {
  margin-top: 192px !important;
  margin-bottom: 192px !important;
}

.mx-196 {
  margin-right: 196px !important;
  margin-left: 196px !important;
}

.my-196 {
  margin-top: 196px !important;
  margin-bottom: 196px !important;
}

.mx-200 {
  margin-right: 200px !important;
  margin-left: 200px !important;
}

.my-200 {
  margin-top: 200px !important;
  margin-bottom: 200px !important;
}

.mx-204 {
  margin-right: 204px !important;
  margin-left: 204px !important;
}

.my-204 {
  margin-top: 204px !important;
  margin-bottom: 204px !important;
}

.mx-208 {
  margin-right: 208px !important;
  margin-left: 208px !important;
}

.my-208 {
  margin-top: 208px !important;
  margin-bottom: 208px !important;
}

.mx-212 {
  margin-right: 212px !important;
  margin-left: 212px !important;
}

.my-212 {
  margin-top: 212px !important;
  margin-bottom: 212px !important;
}

.mx-216 {
  margin-right: 216px !important;
  margin-left: 216px !important;
}

.my-216 {
  margin-top: 216px !important;
  margin-bottom: 216px !important;
}

.mx-220 {
  margin-right: 220px !important;
  margin-left: 220px !important;
}

.my-220 {
  margin-top: 220px !important;
  margin-bottom: 220px !important;
}

.mx-224 {
  margin-right: 224px !important;
  margin-left: 224px !important;
}

.my-224 {
  margin-top: 224px !important;
  margin-bottom: 224px !important;
}

.mx-228 {
  margin-right: 228px !important;
  margin-left: 228px !important;
}

.my-228 {
  margin-top: 228px !important;
  margin-bottom: 228px !important;
}

.mx-232 {
  margin-right: 232px !important;
  margin-left: 232px !important;
}

.my-232 {
  margin-top: 232px !important;
  margin-bottom: 232px !important;
}

.mx-236 {
  margin-right: 236px !important;
  margin-left: 236px !important;
}

.my-236 {
  margin-top: 236px !important;
  margin-bottom: 236px !important;
}

.mx-240 {
  margin-right: 240px !important;
  margin-left: 240px !important;
}

.my-240 {
  margin-top: 240px !important;
  margin-bottom: 240px !important;
}

.mx-244 {
  margin-right: 244px !important;
  margin-left: 244px !important;
}

.my-244 {
  margin-top: 244px !important;
  margin-bottom: 244px !important;
}

.mx-248 {
  margin-right: 248px !important;
  margin-left: 248px !important;
}

.my-248 {
  margin-top: 248px !important;
  margin-bottom: 248px !important;
}

.mx-252 {
  margin-right: 252px !important;
  margin-left: 252px !important;
}

.my-252 {
  margin-top: 252px !important;
  margin-bottom: 252px !important;
}

.mx-256 {
  margin-right: 256px !important;
  margin-left: 256px !important;
}

.my-256 {
  margin-top: 256px !important;
  margin-bottom: 256px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.mr-28 {
  margin-right: 28px !important;
}

.mb-28 {
  margin-bottom: 28px !important;
}

.ml-28 {
  margin-left: 28px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.mr-36 {
  margin-right: 36px !important;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.ml-36 {
  margin-left: 36px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.mt-44 {
  margin-top: 44px !important;
}

.mr-44 {
  margin-right: 44px !important;
}

.mb-44 {
  margin-bottom: 44px !important;
}

.ml-44 {
  margin-left: 44px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.mr-48 {
  margin-right: 48px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.ml-48 {
  margin-left: 48px !important;
}

.mt-52 {
  margin-top: 52px !important;
}

.mr-52 {
  margin-right: 52px !important;
}

.mb-52 {
  margin-bottom: 52px !important;
}

.ml-52 {
  margin-left: 52px !important;
}

.mt-56 {
  margin-top: 56px !important;
}

.mr-56 {
  margin-right: 56px !important;
}

.mb-56 {
  margin-bottom: 56px !important;
}

.ml-56 {
  margin-left: 56px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.mt-64 {
  margin-top: 64px !important;
}

.mr-64 {
  margin-right: 64px !important;
}

.mb-64 {
  margin-bottom: 64px !important;
}

.ml-64 {
  margin-left: 64px !important;
}

.mt-68 {
  margin-top: 68px !important;
}

.mr-68 {
  margin-right: 68px !important;
}

.mb-68 {
  margin-bottom: 68px !important;
}

.ml-68 {
  margin-left: 68px !important;
}

.mt-72 {
  margin-top: 72px !important;
}

.mr-72 {
  margin-right: 72px !important;
}

.mb-72 {
  margin-bottom: 72px !important;
}

.ml-72 {
  margin-left: 72px !important;
}

.mt-76 {
  margin-top: 76px !important;
}

.mr-76 {
  margin-right: 76px !important;
}

.mb-76 {
  margin-bottom: 76px !important;
}

.ml-76 {
  margin-left: 76px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.mt-84 {
  margin-top: 84px !important;
}

.mr-84 {
  margin-right: 84px !important;
}

.mb-84 {
  margin-bottom: 84px !important;
}

.ml-84 {
  margin-left: 84px !important;
}

.mt-88 {
  margin-top: 88px !important;
}

.mr-88 {
  margin-right: 88px !important;
}

.mb-88 {
  margin-bottom: 88px !important;
}

.ml-88 {
  margin-left: 88px !important;
}

.mt-92 {
  margin-top: 92px !important;
}

.mr-92 {
  margin-right: 92px !important;
}

.mb-92 {
  margin-bottom: 92px !important;
}

.ml-92 {
  margin-left: 92px !important;
}

.mt-96 {
  margin-top: 96px !important;
}

.mr-96 {
  margin-right: 96px !important;
}

.mb-96 {
  margin-bottom: 96px !important;
}

.ml-96 {
  margin-left: 96px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.mt-104 {
  margin-top: 104px !important;
}

.mr-104 {
  margin-right: 104px !important;
}

.mb-104 {
  margin-bottom: 104px !important;
}

.ml-104 {
  margin-left: 104px !important;
}

.mt-108 {
  margin-top: 108px !important;
}

.mr-108 {
  margin-right: 108px !important;
}

.mb-108 {
  margin-bottom: 108px !important;
}

.ml-108 {
  margin-left: 108px !important;
}

.mt-112 {
  margin-top: 112px !important;
}

.mr-112 {
  margin-right: 112px !important;
}

.mb-112 {
  margin-bottom: 112px !important;
}

.ml-112 {
  margin-left: 112px !important;
}

.mt-116 {
  margin-top: 116px !important;
}

.mr-116 {
  margin-right: 116px !important;
}

.mb-116 {
  margin-bottom: 116px !important;
}

.ml-116 {
  margin-left: 116px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.ml-120 {
  margin-left: 120px !important;
}

.mt-124 {
  margin-top: 124px !important;
}

.mr-124 {
  margin-right: 124px !important;
}

.mb-124 {
  margin-bottom: 124px !important;
}

.ml-124 {
  margin-left: 124px !important;
}

.mt-128 {
  margin-top: 128px !important;
}

.mr-128 {
  margin-right: 128px !important;
}

.mb-128 {
  margin-bottom: 128px !important;
}

.ml-128 {
  margin-left: 128px !important;
}

.mt-132 {
  margin-top: 132px !important;
}

.mr-132 {
  margin-right: 132px !important;
}

.mb-132 {
  margin-bottom: 132px !important;
}

.ml-132 {
  margin-left: 132px !important;
}

.mt-136 {
  margin-top: 136px !important;
}

.mr-136 {
  margin-right: 136px !important;
}

.mb-136 {
  margin-bottom: 136px !important;
}

.ml-136 {
  margin-left: 136px !important;
}

.mt-140 {
  margin-top: 140px !important;
}

.mr-140 {
  margin-right: 140px !important;
}

.mb-140 {
  margin-bottom: 140px !important;
}

.ml-140 {
  margin-left: 140px !important;
}

.mt-144 {
  margin-top: 144px !important;
}

.mr-144 {
  margin-right: 144px !important;
}

.mb-144 {
  margin-bottom: 144px !important;
}

.ml-144 {
  margin-left: 144px !important;
}

.mt-148 {
  margin-top: 148px !important;
}

.mr-148 {
  margin-right: 148px !important;
}

.mb-148 {
  margin-bottom: 148px !important;
}

.ml-148 {
  margin-left: 148px !important;
}

.mt-152 {
  margin-top: 152px !important;
}

.mr-152 {
  margin-right: 152px !important;
}

.mb-152 {
  margin-bottom: 152px !important;
}

.ml-152 {
  margin-left: 152px !important;
}

.mt-156 {
  margin-top: 156px !important;
}

.mr-156 {
  margin-right: 156px !important;
}

.mb-156 {
  margin-bottom: 156px !important;
}

.ml-156 {
  margin-left: 156px !important;
}

.mt-160 {
  margin-top: 160px !important;
}

.mr-160 {
  margin-right: 160px !important;
}

.mb-160 {
  margin-bottom: 160px !important;
}

.ml-160 {
  margin-left: 160px !important;
}

.mt-164 {
  margin-top: 164px !important;
}

.mr-164 {
  margin-right: 164px !important;
}

.mb-164 {
  margin-bottom: 164px !important;
}

.ml-164 {
  margin-left: 164px !important;
}

.mt-168 {
  margin-top: 168px !important;
}

.mr-168 {
  margin-right: 168px !important;
}

.mb-168 {
  margin-bottom: 168px !important;
}

.ml-168 {
  margin-left: 168px !important;
}

.mt-172 {
  margin-top: 172px !important;
}

.mr-172 {
  margin-right: 172px !important;
}

.mb-172 {
  margin-bottom: 172px !important;
}

.ml-172 {
  margin-left: 172px !important;
}

.mt-176 {
  margin-top: 176px !important;
}

.mr-176 {
  margin-right: 176px !important;
}

.mb-176 {
  margin-bottom: 176px !important;
}

.ml-176 {
  margin-left: 176px !important;
}

.mt-180 {
  margin-top: 180px !important;
}

.mr-180 {
  margin-right: 180px !important;
}

.mb-180 {
  margin-bottom: 180px !important;
}

.ml-180 {
  margin-left: 180px !important;
}

.mt-184 {
  margin-top: 184px !important;
}

.mr-184 {
  margin-right: 184px !important;
}

.mb-184 {
  margin-bottom: 184px !important;
}

.ml-184 {
  margin-left: 184px !important;
}

.mt-188 {
  margin-top: 188px !important;
}

.mr-188 {
  margin-right: 188px !important;
}

.mb-188 {
  margin-bottom: 188px !important;
}

.ml-188 {
  margin-left: 188px !important;
}

.mt-192 {
  margin-top: 192px !important;
}

.mr-192 {
  margin-right: 192px !important;
}

.mb-192 {
  margin-bottom: 192px !important;
}

.ml-192 {
  margin-left: 192px !important;
}

.mt-196 {
  margin-top: 196px !important;
}

.mr-196 {
  margin-right: 196px !important;
}

.mb-196 {
  margin-bottom: 196px !important;
}

.ml-196 {
  margin-left: 196px !important;
}

.mt-200 {
  margin-top: 200px !important;
}

.mr-200 {
  margin-right: 200px !important;
}

.mb-200 {
  margin-bottom: 200px !important;
}

.ml-200 {
  margin-left: 200px !important;
}

.mt-204 {
  margin-top: 204px !important;
}

.mr-204 {
  margin-right: 204px !important;
}

.mb-204 {
  margin-bottom: 204px !important;
}

.ml-204 {
  margin-left: 204px !important;
}

.mt-208 {
  margin-top: 208px !important;
}

.mr-208 {
  margin-right: 208px !important;
}

.mb-208 {
  margin-bottom: 208px !important;
}

.ml-208 {
  margin-left: 208px !important;
}

.mt-212 {
  margin-top: 212px !important;
}

.mr-212 {
  margin-right: 212px !important;
}

.mb-212 {
  margin-bottom: 212px !important;
}

.ml-212 {
  margin-left: 212px !important;
}

.mt-216 {
  margin-top: 216px !important;
}

.mr-216 {
  margin-right: 216px !important;
}

.mb-216 {
  margin-bottom: 216px !important;
}

.ml-216 {
  margin-left: 216px !important;
}

.mt-220 {
  margin-top: 220px !important;
}

.mr-220 {
  margin-right: 220px !important;
}

.mb-220 {
  margin-bottom: 220px !important;
}

.ml-220 {
  margin-left: 220px !important;
}

.mt-224 {
  margin-top: 224px !important;
}

.mr-224 {
  margin-right: 224px !important;
}

.mb-224 {
  margin-bottom: 224px !important;
}

.ml-224 {
  margin-left: 224px !important;
}

.mt-228 {
  margin-top: 228px !important;
}

.mr-228 {
  margin-right: 228px !important;
}

.mb-228 {
  margin-bottom: 228px !important;
}

.ml-228 {
  margin-left: 228px !important;
}

.mt-232 {
  margin-top: 232px !important;
}

.mr-232 {
  margin-right: 232px !important;
}

.mb-232 {
  margin-bottom: 232px !important;
}

.ml-232 {
  margin-left: 232px !important;
}

.mt-236 {
  margin-top: 236px !important;
}

.mr-236 {
  margin-right: 236px !important;
}

.mb-236 {
  margin-bottom: 236px !important;
}

.ml-236 {
  margin-left: 236px !important;
}

.mt-240 {
  margin-top: 240px !important;
}

.mr-240 {
  margin-right: 240px !important;
}

.mb-240 {
  margin-bottom: 240px !important;
}

.ml-240 {
  margin-left: 240px !important;
}

.mt-244 {
  margin-top: 244px !important;
}

.mr-244 {
  margin-right: 244px !important;
}

.mb-244 {
  margin-bottom: 244px !important;
}

.ml-244 {
  margin-left: 244px !important;
}

.mt-248 {
  margin-top: 248px !important;
}

.mr-248 {
  margin-right: 248px !important;
}

.mb-248 {
  margin-bottom: 248px !important;
}

.ml-248 {
  margin-left: 248px !important;
}

.mt-252 {
  margin-top: 252px !important;
}

.mr-252 {
  margin-right: 252px !important;
}

.mb-252 {
  margin-bottom: 252px !important;
}

.ml-252 {
  margin-left: 252px !important;
}

.mt-256 {
  margin-top: 256px !important;
}

.mr-256 {
  margin-right: 256px !important;
}

.mb-256 {
  margin-bottom: 256px !important;
}

.ml-256 {
  margin-left: 256px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-4 {
  padding: 4px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-28 {
  padding: 28px !important;
}

.p-32 {
  padding: 32px !important;
}

.p-36 {
  padding: 36px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-44 {
  padding: 44px !important;
}

.p-48 {
  padding: 48px !important;
}

.p-52 {
  padding: 52px !important;
}

.p-56 {
  padding: 56px !important;
}

.p-60 {
  padding: 60px !important;
}

.p-64 {
  padding: 64px !important;
}

.p-68 {
  padding: 68px !important;
}

.p-72 {
  padding: 72px !important;
}

.p-76 {
  padding: 76px !important;
}

.p-80 {
  padding: 80px !important;
}

.p-84 {
  padding: 84px !important;
}

.p-88 {
  padding: 88px !important;
}

.p-92 {
  padding: 92px !important;
}

.p-96 {
  padding: 96px !important;
}

.p-100 {
  padding: 100px !important;
}

.p-104 {
  padding: 104px !important;
}

.p-108 {
  padding: 108px !important;
}

.p-112 {
  padding: 112px !important;
}

.p-116 {
  padding: 116px !important;
}

.p-120 {
  padding: 120px !important;
}

.p-124 {
  padding: 124px !important;
}

.p-128 {
  padding: 128px !important;
}

.p-132 {
  padding: 132px !important;
}

.p-136 {
  padding: 136px !important;
}

.p-140 {
  padding: 140px !important;
}

.p-144 {
  padding: 144px !important;
}

.p-148 {
  padding: 148px !important;
}

.p-152 {
  padding: 152px !important;
}

.p-156 {
  padding: 156px !important;
}

.p-160 {
  padding: 160px !important;
}

.p-164 {
  padding: 164px !important;
}

.p-168 {
  padding: 168px !important;
}

.p-172 {
  padding: 172px !important;
}

.p-176 {
  padding: 176px !important;
}

.p-180 {
  padding: 180px !important;
}

.p-184 {
  padding: 184px !important;
}

.p-188 {
  padding: 188px !important;
}

.p-192 {
  padding: 192px !important;
}

.p-196 {
  padding: 196px !important;
}

.p-200 {
  padding: 200px !important;
}

.p-204 {
  padding: 204px !important;
}

.p-208 {
  padding: 208px !important;
}

.p-212 {
  padding: 212px !important;
}

.p-216 {
  padding: 216px !important;
}

.p-220 {
  padding: 220px !important;
}

.p-224 {
  padding: 224px !important;
}

.p-228 {
  padding: 228px !important;
}

.p-232 {
  padding: 232px !important;
}

.p-236 {
  padding: 236px !important;
}

.p-240 {
  padding: 240px !important;
}

.p-244 {
  padding: 244px !important;
}

.p-248 {
  padding: 248px !important;
}

.p-252 {
  padding: 252px !important;
}

.p-256 {
  padding: 256px !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-4 {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.py-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.px-8 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.px-12 {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.px-16 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.px-20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.px-24 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.px-28 {
  padding-right: 28px !important;
  padding-left: 28px !important;
}

.py-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.px-32 {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.py-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.px-36 {
  padding-right: 36px !important;
  padding-left: 36px !important;
}

.py-36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.px-40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.px-44 {
  padding-right: 44px !important;
  padding-left: 44px !important;
}

.py-44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.px-48 {
  padding-right: 48px !important;
  padding-left: 48px !important;
}

.py-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.px-52 {
  padding-right: 52px !important;
  padding-left: 52px !important;
}

.py-52 {
  padding-top: 52px !important;
  padding-bottom: 52px !important;
}

.px-56 {
  padding-right: 56px !important;
  padding-left: 56px !important;
}

.py-56 {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}

.px-60 {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.px-64 {
  padding-right: 64px !important;
  padding-left: 64px !important;
}

.py-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.px-68 {
  padding-right: 68px !important;
  padding-left: 68px !important;
}

.py-68 {
  padding-top: 68px !important;
  padding-bottom: 68px !important;
}

.px-72 {
  padding-right: 72px !important;
  padding-left: 72px !important;
}

.py-72 {
  padding-top: 72px !important;
  padding-bottom: 72px !important;
}

.px-76 {
  padding-right: 76px !important;
  padding-left: 76px !important;
}

.py-76 {
  padding-top: 76px !important;
  padding-bottom: 76px !important;
}

.px-80 {
  padding-right: 80px !important;
  padding-left: 80px !important;
}

.py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.px-84 {
  padding-right: 84px !important;
  padding-left: 84px !important;
}

.py-84 {
  padding-top: 84px !important;
  padding-bottom: 84px !important;
}

.px-88 {
  padding-right: 88px !important;
  padding-left: 88px !important;
}

.py-88 {
  padding-top: 88px !important;
  padding-bottom: 88px !important;
}

.px-92 {
  padding-right: 92px !important;
  padding-left: 92px !important;
}

.py-92 {
  padding-top: 92px !important;
  padding-bottom: 92px !important;
}

.px-96 {
  padding-right: 96px !important;
  padding-left: 96px !important;
}

.py-96 {
  padding-top: 96px !important;
  padding-bottom: 96px !important;
}

.px-100 {
  padding-right: 100px !important;
  padding-left: 100px !important;
}

.py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.px-104 {
  padding-right: 104px !important;
  padding-left: 104px !important;
}

.py-104 {
  padding-top: 104px !important;
  padding-bottom: 104px !important;
}

.px-108 {
  padding-right: 108px !important;
  padding-left: 108px !important;
}

.py-108 {
  padding-top: 108px !important;
  padding-bottom: 108px !important;
}

.px-112 {
  padding-right: 112px !important;
  padding-left: 112px !important;
}

.py-112 {
  padding-top: 112px !important;
  padding-bottom: 112px !important;
}

.px-116 {
  padding-right: 116px !important;
  padding-left: 116px !important;
}

.py-116 {
  padding-top: 116px !important;
  padding-bottom: 116px !important;
}

.px-120 {
  padding-right: 120px !important;
  padding-left: 120px !important;
}

.py-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.px-124 {
  padding-right: 124px !important;
  padding-left: 124px !important;
}

.py-124 {
  padding-top: 124px !important;
  padding-bottom: 124px !important;
}

.px-128 {
  padding-right: 128px !important;
  padding-left: 128px !important;
}

.py-128 {
  padding-top: 128px !important;
  padding-bottom: 128px !important;
}

.px-132 {
  padding-right: 132px !important;
  padding-left: 132px !important;
}

.py-132 {
  padding-top: 132px !important;
  padding-bottom: 132px !important;
}

.px-136 {
  padding-right: 136px !important;
  padding-left: 136px !important;
}

.py-136 {
  padding-top: 136px !important;
  padding-bottom: 136px !important;
}

.px-140 {
  padding-right: 140px !important;
  padding-left: 140px !important;
}

.py-140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}

.px-144 {
  padding-right: 144px !important;
  padding-left: 144px !important;
}

.py-144 {
  padding-top: 144px !important;
  padding-bottom: 144px !important;
}

.px-148 {
  padding-right: 148px !important;
  padding-left: 148px !important;
}

.py-148 {
  padding-top: 148px !important;
  padding-bottom: 148px !important;
}

.px-152 {
  padding-right: 152px !important;
  padding-left: 152px !important;
}

.py-152 {
  padding-top: 152px !important;
  padding-bottom: 152px !important;
}

.px-156 {
  padding-right: 156px !important;
  padding-left: 156px !important;
}

.py-156 {
  padding-top: 156px !important;
  padding-bottom: 156px !important;
}

.px-160 {
  padding-right: 160px !important;
  padding-left: 160px !important;
}

.py-160 {
  padding-top: 160px !important;
  padding-bottom: 160px !important;
}

.px-164 {
  padding-right: 164px !important;
  padding-left: 164px !important;
}

.py-164 {
  padding-top: 164px !important;
  padding-bottom: 164px !important;
}

.px-168 {
  padding-right: 168px !important;
  padding-left: 168px !important;
}

.py-168 {
  padding-top: 168px !important;
  padding-bottom: 168px !important;
}

.px-172 {
  padding-right: 172px !important;
  padding-left: 172px !important;
}

.py-172 {
  padding-top: 172px !important;
  padding-bottom: 172px !important;
}

.px-176 {
  padding-right: 176px !important;
  padding-left: 176px !important;
}

.py-176 {
  padding-top: 176px !important;
  padding-bottom: 176px !important;
}

.px-180 {
  padding-right: 180px !important;
  padding-left: 180px !important;
}

.py-180 {
  padding-top: 180px !important;
  padding-bottom: 180px !important;
}

.px-184 {
  padding-right: 184px !important;
  padding-left: 184px !important;
}

.py-184 {
  padding-top: 184px !important;
  padding-bottom: 184px !important;
}

.px-188 {
  padding-right: 188px !important;
  padding-left: 188px !important;
}

.py-188 {
  padding-top: 188px !important;
  padding-bottom: 188px !important;
}

.px-192 {
  padding-right: 192px !important;
  padding-left: 192px !important;
}

.py-192 {
  padding-top: 192px !important;
  padding-bottom: 192px !important;
}

.px-196 {
  padding-right: 196px !important;
  padding-left: 196px !important;
}

.py-196 {
  padding-top: 196px !important;
  padding-bottom: 196px !important;
}

.px-200 {
  padding-right: 200px !important;
  padding-left: 200px !important;
}

.py-200 {
  padding-top: 200px !important;
  padding-bottom: 200px !important;
}

.px-204 {
  padding-right: 204px !important;
  padding-left: 204px !important;
}

.py-204 {
  padding-top: 204px !important;
  padding-bottom: 204px !important;
}

.px-208 {
  padding-right: 208px !important;
  padding-left: 208px !important;
}

.py-208 {
  padding-top: 208px !important;
  padding-bottom: 208px !important;
}

.px-212 {
  padding-right: 212px !important;
  padding-left: 212px !important;
}

.py-212 {
  padding-top: 212px !important;
  padding-bottom: 212px !important;
}

.px-216 {
  padding-right: 216px !important;
  padding-left: 216px !important;
}

.py-216 {
  padding-top: 216px !important;
  padding-bottom: 216px !important;
}

.px-220 {
  padding-right: 220px !important;
  padding-left: 220px !important;
}

.py-220 {
  padding-top: 220px !important;
  padding-bottom: 220px !important;
}

.px-224 {
  padding-right: 224px !important;
  padding-left: 224px !important;
}

.py-224 {
  padding-top: 224px !important;
  padding-bottom: 224px !important;
}

.px-228 {
  padding-right: 228px !important;
  padding-left: 228px !important;
}

.py-228 {
  padding-top: 228px !important;
  padding-bottom: 228px !important;
}

.px-232 {
  padding-right: 232px !important;
  padding-left: 232px !important;
}

.py-232 {
  padding-top: 232px !important;
  padding-bottom: 232px !important;
}

.px-236 {
  padding-right: 236px !important;
  padding-left: 236px !important;
}

.py-236 {
  padding-top: 236px !important;
  padding-bottom: 236px !important;
}

.px-240 {
  padding-right: 240px !important;
  padding-left: 240px !important;
}

.py-240 {
  padding-top: 240px !important;
  padding-bottom: 240px !important;
}

.px-244 {
  padding-right: 244px !important;
  padding-left: 244px !important;
}

.py-244 {
  padding-top: 244px !important;
  padding-bottom: 244px !important;
}

.px-248 {
  padding-right: 248px !important;
  padding-left: 248px !important;
}

.py-248 {
  padding-top: 248px !important;
  padding-bottom: 248px !important;
}

.px-252 {
  padding-right: 252px !important;
  padding-left: 252px !important;
}

.py-252 {
  padding-top: 252px !important;
  padding-bottom: 252px !important;
}

.px-256 {
  padding-right: 256px !important;
  padding-left: 256px !important;
}

.py-256 {
  padding-top: 256px !important;
  padding-bottom: 256px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pt-28 {
  padding-top: 28px !important;
}

.pr-28 {
  padding-right: 28px !important;
}

.pb-28 {
  padding-bottom: 28px !important;
}

.pl-28 {
  padding-left: 28px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

.pt-36 {
  padding-top: 36px !important;
}

.pr-36 {
  padding-right: 36px !important;
}

.pb-36 {
  padding-bottom: 36px !important;
}

.pl-36 {
  padding-left: 36px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pt-44 {
  padding-top: 44px !important;
}

.pr-44 {
  padding-right: 44px !important;
}

.pb-44 {
  padding-bottom: 44px !important;
}

.pl-44 {
  padding-left: 44px !important;
}

.pt-48 {
  padding-top: 48px !important;
}

.pr-48 {
  padding-right: 48px !important;
}

.pb-48 {
  padding-bottom: 48px !important;
}

.pl-48 {
  padding-left: 48px !important;
}

.pt-52 {
  padding-top: 52px !important;
}

.pr-52 {
  padding-right: 52px !important;
}

.pb-52 {
  padding-bottom: 52px !important;
}

.pl-52 {
  padding-left: 52px !important;
}

.pt-56 {
  padding-top: 56px !important;
}

.pr-56 {
  padding-right: 56px !important;
}

.pb-56 {
  padding-bottom: 56px !important;
}

.pl-56 {
  padding-left: 56px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pt-64 {
  padding-top: 64px !important;
}

.pr-64 {
  padding-right: 64px !important;
}

.pb-64 {
  padding-bottom: 64px !important;
}

.pl-64 {
  padding-left: 64px !important;
}

.pt-68 {
  padding-top: 68px !important;
}

.pr-68 {
  padding-right: 68px !important;
}

.pb-68 {
  padding-bottom: 68px !important;
}

.pl-68 {
  padding-left: 68px !important;
}

.pt-72 {
  padding-top: 72px !important;
}

.pr-72 {
  padding-right: 72px !important;
}

.pb-72 {
  padding-bottom: 72px !important;
}

.pl-72 {
  padding-left: 72px !important;
}

.pt-76 {
  padding-top: 76px !important;
}

.pr-76 {
  padding-right: 76px !important;
}

.pb-76 {
  padding-bottom: 76px !important;
}

.pl-76 {
  padding-left: 76px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pt-84 {
  padding-top: 84px !important;
}

.pr-84 {
  padding-right: 84px !important;
}

.pb-84 {
  padding-bottom: 84px !important;
}

.pl-84 {
  padding-left: 84px !important;
}

.pt-88 {
  padding-top: 88px !important;
}

.pr-88 {
  padding-right: 88px !important;
}

.pb-88 {
  padding-bottom: 88px !important;
}

.pl-88 {
  padding-left: 88px !important;
}

.pt-92 {
  padding-top: 92px !important;
}

.pr-92 {
  padding-right: 92px !important;
}

.pb-92 {
  padding-bottom: 92px !important;
}

.pl-92 {
  padding-left: 92px !important;
}

.pt-96 {
  padding-top: 96px !important;
}

.pr-96 {
  padding-right: 96px !important;
}

.pb-96 {
  padding-bottom: 96px !important;
}

.pl-96 {
  padding-left: 96px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.pt-104 {
  padding-top: 104px !important;
}

.pr-104 {
  padding-right: 104px !important;
}

.pb-104 {
  padding-bottom: 104px !important;
}

.pl-104 {
  padding-left: 104px !important;
}

.pt-108 {
  padding-top: 108px !important;
}

.pr-108 {
  padding-right: 108px !important;
}

.pb-108 {
  padding-bottom: 108px !important;
}

.pl-108 {
  padding-left: 108px !important;
}

.pt-112 {
  padding-top: 112px !important;
}

.pr-112 {
  padding-right: 112px !important;
}

.pb-112 {
  padding-bottom: 112px !important;
}

.pl-112 {
  padding-left: 112px !important;
}

.pt-116 {
  padding-top: 116px !important;
}

.pr-116 {
  padding-right: 116px !important;
}

.pb-116 {
  padding-bottom: 116px !important;
}

.pl-116 {
  padding-left: 116px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pl-120 {
  padding-left: 120px !important;
}

.pt-124 {
  padding-top: 124px !important;
}

.pr-124 {
  padding-right: 124px !important;
}

.pb-124 {
  padding-bottom: 124px !important;
}

.pl-124 {
  padding-left: 124px !important;
}

.pt-128 {
  padding-top: 128px !important;
}

.pr-128 {
  padding-right: 128px !important;
}

.pb-128 {
  padding-bottom: 128px !important;
}

.pl-128 {
  padding-left: 128px !important;
}

.pt-132 {
  padding-top: 132px !important;
}

.pr-132 {
  padding-right: 132px !important;
}

.pb-132 {
  padding-bottom: 132px !important;
}

.pl-132 {
  padding-left: 132px !important;
}

.pt-136 {
  padding-top: 136px !important;
}

.pr-136 {
  padding-right: 136px !important;
}

.pb-136 {
  padding-bottom: 136px !important;
}

.pl-136 {
  padding-left: 136px !important;
}

.pt-140 {
  padding-top: 140px !important;
}

.pr-140 {
  padding-right: 140px !important;
}

.pb-140 {
  padding-bottom: 140px !important;
}

.pl-140 {
  padding-left: 140px !important;
}

.pt-144 {
  padding-top: 144px !important;
}

.pr-144 {
  padding-right: 144px !important;
}

.pb-144 {
  padding-bottom: 144px !important;
}

.pl-144 {
  padding-left: 144px !important;
}

.pt-148 {
  padding-top: 148px !important;
}

.pr-148 {
  padding-right: 148px !important;
}

.pb-148 {
  padding-bottom: 148px !important;
}

.pl-148 {
  padding-left: 148px !important;
}

.pt-152 {
  padding-top: 152px !important;
}

.pr-152 {
  padding-right: 152px !important;
}

.pb-152 {
  padding-bottom: 152px !important;
}

.pl-152 {
  padding-left: 152px !important;
}

.pt-156 {
  padding-top: 156px !important;
}

.pr-156 {
  padding-right: 156px !important;
}

.pb-156 {
  padding-bottom: 156px !important;
}

.pl-156 {
  padding-left: 156px !important;
}

.pt-160 {
  padding-top: 160px !important;
}

.pr-160 {
  padding-right: 160px !important;
}

.pb-160 {
  padding-bottom: 160px !important;
}

.pl-160 {
  padding-left: 160px !important;
}

.pt-164 {
  padding-top: 164px !important;
}

.pr-164 {
  padding-right: 164px !important;
}

.pb-164 {
  padding-bottom: 164px !important;
}

.pl-164 {
  padding-left: 164px !important;
}

.pt-168 {
  padding-top: 168px !important;
}

.pr-168 {
  padding-right: 168px !important;
}

.pb-168 {
  padding-bottom: 168px !important;
}

.pl-168 {
  padding-left: 168px !important;
}

.pt-172 {
  padding-top: 172px !important;
}

.pr-172 {
  padding-right: 172px !important;
}

.pb-172 {
  padding-bottom: 172px !important;
}

.pl-172 {
  padding-left: 172px !important;
}

.pt-176 {
  padding-top: 176px !important;
}

.pr-176 {
  padding-right: 176px !important;
}

.pb-176 {
  padding-bottom: 176px !important;
}

.pl-176 {
  padding-left: 176px !important;
}

.pt-180 {
  padding-top: 180px !important;
}

.pr-180 {
  padding-right: 180px !important;
}

.pb-180 {
  padding-bottom: 180px !important;
}

.pl-180 {
  padding-left: 180px !important;
}

.pt-184 {
  padding-top: 184px !important;
}

.pr-184 {
  padding-right: 184px !important;
}

.pb-184 {
  padding-bottom: 184px !important;
}

.pl-184 {
  padding-left: 184px !important;
}

.pt-188 {
  padding-top: 188px !important;
}

.pr-188 {
  padding-right: 188px !important;
}

.pb-188 {
  padding-bottom: 188px !important;
}

.pl-188 {
  padding-left: 188px !important;
}

.pt-192 {
  padding-top: 192px !important;
}

.pr-192 {
  padding-right: 192px !important;
}

.pb-192 {
  padding-bottom: 192px !important;
}

.pl-192 {
  padding-left: 192px !important;
}

.pt-196 {
  padding-top: 196px !important;
}

.pr-196 {
  padding-right: 196px !important;
}

.pb-196 {
  padding-bottom: 196px !important;
}

.pl-196 {
  padding-left: 196px !important;
}

.pt-200 {
  padding-top: 200px !important;
}

.pr-200 {
  padding-right: 200px !important;
}

.pb-200 {
  padding-bottom: 200px !important;
}

.pl-200 {
  padding-left: 200px !important;
}

.pt-204 {
  padding-top: 204px !important;
}

.pr-204 {
  padding-right: 204px !important;
}

.pb-204 {
  padding-bottom: 204px !important;
}

.pl-204 {
  padding-left: 204px !important;
}

.pt-208 {
  padding-top: 208px !important;
}

.pr-208 {
  padding-right: 208px !important;
}

.pb-208 {
  padding-bottom: 208px !important;
}

.pl-208 {
  padding-left: 208px !important;
}

.pt-212 {
  padding-top: 212px !important;
}

.pr-212 {
  padding-right: 212px !important;
}

.pb-212 {
  padding-bottom: 212px !important;
}

.pl-212 {
  padding-left: 212px !important;
}

.pt-216 {
  padding-top: 216px !important;
}

.pr-216 {
  padding-right: 216px !important;
}

.pb-216 {
  padding-bottom: 216px !important;
}

.pl-216 {
  padding-left: 216px !important;
}

.pt-220 {
  padding-top: 220px !important;
}

.pr-220 {
  padding-right: 220px !important;
}

.pb-220 {
  padding-bottom: 220px !important;
}

.pl-220 {
  padding-left: 220px !important;
}

.pt-224 {
  padding-top: 224px !important;
}

.pr-224 {
  padding-right: 224px !important;
}

.pb-224 {
  padding-bottom: 224px !important;
}

.pl-224 {
  padding-left: 224px !important;
}

.pt-228 {
  padding-top: 228px !important;
}

.pr-228 {
  padding-right: 228px !important;
}

.pb-228 {
  padding-bottom: 228px !important;
}

.pl-228 {
  padding-left: 228px !important;
}

.pt-232 {
  padding-top: 232px !important;
}

.pr-232 {
  padding-right: 232px !important;
}

.pb-232 {
  padding-bottom: 232px !important;
}

.pl-232 {
  padding-left: 232px !important;
}

.pt-236 {
  padding-top: 236px !important;
}

.pr-236 {
  padding-right: 236px !important;
}

.pb-236 {
  padding-bottom: 236px !important;
}

.pl-236 {
  padding-left: 236px !important;
}

.pt-240 {
  padding-top: 240px !important;
}

.pr-240 {
  padding-right: 240px !important;
}

.pb-240 {
  padding-bottom: 240px !important;
}

.pl-240 {
  padding-left: 240px !important;
}

.pt-244 {
  padding-top: 244px !important;
}

.pr-244 {
  padding-right: 244px !important;
}

.pb-244 {
  padding-bottom: 244px !important;
}

.pl-244 {
  padding-left: 244px !important;
}

.pt-248 {
  padding-top: 248px !important;
}

.pr-248 {
  padding-right: 248px !important;
}

.pb-248 {
  padding-bottom: 248px !important;
}

.pl-248 {
  padding-left: 248px !important;
}

.pt-252 {
  padding-top: 252px !important;
}

.pr-252 {
  padding-right: 252px !important;
}

.pb-252 {
  padding-bottom: 252px !important;
}

.pl-252 {
  padding-left: 252px !important;
}

.pt-256 {
  padding-top: 256px !important;
}

.pr-256 {
  padding-right: 256px !important;
}

.pb-256 {
  padding-bottom: 256px !important;
}

.pl-256 {
  padding-left: 256px !important;
}

/* FINAL helpers */
/* INICIO helpers texto */
.text-white {
  color: white;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: right;
}

.text-start {
  text-align: left;
}

/* FINAL helpers texto */
/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: proximaNova;
  src: url(assets/fonts/ProximaNova-Regular.otf) format("opentype");
}
* {
  font-family: "proximaNova", sans-serif;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.02em;
}

html,
body {
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  background-color: #F0F0F0;
  margin: 0;
}
html h1,
body h1 {
  font-size: 1.125em;
}
html div,
body div {
  font-size: 14px;
}

.mat-drawer-content {
  overflow: hidden !important;
}

/* Validations*/
.mat-error {
  font-size: 11px !important;
}

/*Buttons*/
.btn {
  width: 8.57em;
  height: 2.57em;
}

.btn__primary {
  background-color: #0058A4;
  color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
}

.btn__primary:disabled,
.btn__secondary:disabled {
  cursor: not-allowed !important;
}

.btn__primary:disabled {
  background: #bfd5e8 !important;
  color: #FFFFFF !important;
  border: 1px solid #bfd5e8 !important;
}

.btn__secondary-red:disabled,
.btn__secondary:disabled {
  background: #ffffff !important;
  color: #aaaaaa !important;
  border: 1px solid #ffffff !important;
}

.btn__secondary {
  border: 0.0714em solid #0058A4 !important;
  box-sizing: border-box;
  border-radius: 0.285em;
  color: #0058A4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
}

.btn__secondary-red {
  border: 0.0714em solid #F23F3F !important;
  box-sizing: border-box;
  border-radius: 0.285em;
  color: #F23F3F;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
}

.btn__link {
  color: #0058A4;
  cursor: pointer;
}

/*Angular Material form Inputs*/
.mat-select-value {
  color: #0058A4;
}

mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix {
  padding: 0.4em 0px;
}

mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix > span.mat-form-field-label-wrapper {
  top: -1.5em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.1em) scale(0.75);
  width: 133.33333%;
}

.mat-form-field-appearance-outline .mat-form-field-outline .mat-form-field-outline-thick {
  color: #0058A4;
}

.mat-form-field-outline {
  background-color: white !important;
  border-radius: 5px;
  color: #0058A4;
  border-color: #0058A4;
}

input.mat-input-element {
  color: #0058A4;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #0058A4;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #0058A4;
}

/* Angular Material Icons */
.mat-icon {
  color: #0058A4;
}

/*Angular Material Slide*/
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(133, 177, 216, 0.8);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #0058A4;
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #0058A4;
}

/* Angular Material checkboxes */
::ng-deep .mat-checkbox .mat-checkbox-frame {
  border-color: #0058A4;
}

::ng-deep .mat-checkbox-checked .mat-checkbox-background {
  background-color: #0058A4 !important;
}

::ng-deep .mat-accent .mat-ripple-element {
  background-color: #0058A4 !important;
}

::ng-deep .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #0058A4 !important;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #0058A4 !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0058A4 !important;
}

/* Client dialog panel*/
.client-dialog {
  margin: auto;
  top: 3.357em;
  position: absolute !important;
  width: 100%;
  height: 90%;
}
.client-dialog .mat-dialog-content {
  max-height: 100%;
}

.pet-form-dialog {
  margin: auto;
  width: 70%;
  max-height: 80%;
}

/* Client unmerge dialog panel*/
.unmerge-dialog {
  position: absolute !important;
  min-width: 80%;
  min-height: 65%;
}
.unmerge-dialog .mat-dialog-content {
  max-height: 100%;
}

/* Family attributes*/
.family-attributes-dialog {
  position: absolute !important;
  min-width: 80%;
  min-height: 80%;
}
.family-attributes-dialog .mat-dialog-content {
  max-height: 100%;
}

.action-dialog {
  min-width: 80%;
  max-height: 80%;
}

/* TAGS */
.tag-animal-type {
  color: #FFFFFF;
  width: 6.857em;
  height: 1.285em;
  text-align: center;
  font-size: 11px;
  padding-top: 0.357em;
  border-radius: 0.181em;
}

.tag-dog {
  background-color: #267401;
}

.tag-cat {
  background-color: #FF9900;
}

.tag-bird {
  background-color: #BC0066;
}

.tag-fish {
  background-color: #001F8D;
}

.tag-rodent {
  background-color: #784100;
}

.tag-other-animal {
  background-color: #545454;
}

.tag-pet-breed {
  background-color: #0058A4;
  color: #FFFFFF;
  width: 6.857em;
  height: 1.285em;
  text-align: center;
  font-size: 11px;
  padding-top: 0.357em;
  border-radius: 0.181em;
  margin-left: 1.272em;
}

.mat-simple-snackbar .ng-star-inserted {
  color: #0058A4;
}

.mat-snack-bar-container {
  font-family: Proxima Nova;
  font-size: 14px;
  line-height: 130%;
  color: #000000;
  letter-spacing: 0.02em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 2.14em !important;
  background-color: #FFFFFF;
}

.ng-star-inserted.current {
  border-radius: 50%;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #0058A4;
}

.mat-progress-bar .mat-progress-bar-buffer {
  background-color: #BFD5E8;
}

.mat-progress-bar .mat-progress-bar-fill::after {
  background-color: #0058A4 !important;
}

.form .mat-form-field {
  margin-right: 10px;
}
.form .full-width {
  width: 100%;
}

.stepper-modal {
  max-width: 767px;
}

/* input ancho completo */
.form-w-100,
.form-w-50,
.form-w-33 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.form-w-100 {
  width: calc(100% - 16px);
}

.form-w-50 {
  width: calc(50% - 16px);
}

.form-w-33 {
  width: calc(33% - 14px);
}

.mat-step-header {
  flex-direction: column;
}

.mat-horizontal-stepper-header .mat-step-icon {
  margin-right: 0 !important;
  margin-bottom: 4px;
}

.mat-step-header {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.mat-step-icon {
  width: 50px !important;
  height: 50px !important;
}

.mat-step-icon .mat-icon {
  font-size: 25px !important;
  width: 25px !important;
  height: 25px !important;
}

.mat-step-label {
  text-align: center !important;
}

.stepper-buttons-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.stepper-buttons-container button {
  margin-left: 8px;
}

.stepper-buttons-container button:first-child {
  margin-left: 0;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background: #0058A4 !important;
  color: white !important;
}

.mat-step-icon-content .mat-icon,
.mat-stepper .mat-icon {
  color: white !important;
}

.mat-horizontal-stepper-content {
  position: relative;
}

.mat-dialog-container .mat-horizontal-content-container {
  padding-bottom: 0px;
}

.component_list .list__header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.component_list .list__header h1 {
  flex: 1 auto;
}
.component_list .list__header .mat-form-field {
  top: 0.65em;
  width: 18em;
}
.component_list .list__header ::ng-deep.mat-form-field-outline {
  background-color: #F0F0F0;
}
.component_list .list__header .remove-search {
  cursor: pointer;
}
.component_list .list__container {
  display: flex;
  flex-wrap: nowrap;
}
.component_list .list__body,
.component_list .list__grid {
  width: 100%;
}
.component_list .list__grid .mat-table {
  border-radius: 0.857em 0.857em 0 0;
}
.component_list .mat-table .action-header,
.component_list .mat-table .action-row {
  width: 50px;
}
.component_list .list__header {
  width: 100%;
  margin-top: 3.2em;
}
.component_list .list__header h1 {
  flex: 1 auto;
}
.component_list .list__header .mat-form-field {
  top: 0.65em;
}

.ngx-file-drop__drop-zone--over,
.ngx-file-drop__drop-zone--over[_ngcontent-aqt-c28] {
  background-color: transparent !important;
}

.mat-table td.mat-cell:first-of-type,
.mat-table td.mat-footer-cell:first-of-type,
.mat-table th.mat-header-cell:first-of-type {
  padding-left: 24px !important;
}
@media screen and (min-width: 992px) {
  .mat-table td.number {
    text-align: right;
    padding-right: 70px !important;
  }
}
.mat-table td.negative {
  background-color: #fac5c5;
}
.mat-table td.total-1 {
  background-color: #829fd9;
}
.mat-table td.total-2 {
  background-color: #5c73f2;
}
.mat-table td.total-3 {
  background-color: #3e38f2;
}
.mat-table th {
  white-space: normal !important;
  min-width: 30px;
}
.mat-table td,
.mat-table th {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.mat-table td a {
  text-decoration: none;
  font-weight: 400;
  color: #0058a4;
}
.mat-table td.small {
  font-size: 12px;
}
.mat-table th.width-1 {
  min-width: 150px;
}
.mat-table th.width-2 {
  min-width: 200px;
}
.mat-table th.width-3 {
  min-width: 250px;
}

.client-list__grid {
  min-height: 200px;
  position: relative;
}

.columnTitleMore {
  text-align: center;
}

.iconCol {
  flex: 0 0 100px;
}

.channel-ico {
  width: 30px;
}

.noBordes {
  border: none;
  background: none;
}

.mat-table-sticky-border-elem-right {
  border-left: 1px solid #e0e0e0;
}

.mat-table-sticky-border-elem-left {
  border-right: 1px solid #e0e0e0;
}

.more-btn {
  min-width: 0px !important;
  padding: 0px !important;
}

.mat-table .action-header {
  min-width: 0px;
}

.rotate-4 {
  animation-name: rotar360;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-delay: 0;
  animation-timing-function: linear;
}

.titleLink {
  color: #0058A4;
  margin-left: 8px;
  cursor: pointer;
  display: inline-block;
}

.titleLink:hover {
  color: #004A8B;
  text-decoration: underline;
}

@media screen and (max-width: 1850px) {
  .layout__container {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media screen and (max-width: 991px) {
  .layout__container {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
.number-user__selector mat-label {
  white-space: nowrap;
}

.number-user__selector mat-select.SelectsFixedWidth {
  min-width: 80px;
  text-align: center;
}

.noMostrarDisabled {
  transition: all 0.15s ease-in-out;
}

.noMostrarDisabled[disabled] {
  opacity: 0.75;
  filter: saturate(0);
  pointer-events: none;
  transition: all 0.15s ease-in-out;
}

.destacadoBlack {
  font-weight: 600;
  color: black;
}

/* elementos clickeables que necesitan la manito en el cursor */
.estrellaHighlight,
.cursorPointer {
  cursor: pointer;
}

/* INICIO badges custom, que se muestran independiemente */
.customBadge {
  color: white;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  padding: 3px;
  border-radius: 50%;
  display: inline-flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  cursor: default;
}

/* FINAL badges custom, que se muestran independiemente */
/* INICIO variante boton small */
.btn_small {
  padding: 0 8px 3px 2px !important;
  cursor: pointer;
}

.btn_small,
.btn_small .mat-button-wrapper {
  font-size: 11px;
}

.btn_small .mat-icon {
  font-size: 18px;
  transform: translateY(4px);
}

/* FINAL variante boton small */
.btn__secondary {
  background-color: white;
}

.btn__secondary:hover {
  background-color: #f5f5f5;
}

/* INICIO chips pequeños */
.smallChip {
  font-size: 12px !important;
  padding: 5px 6px 4px 6px !important;
  max-height: auto !important;
  min-height: 25px !important;
  border-radius: 5px !important;
  margin-right: 5px;
}

/* FINAL chips pequeños */
/* INICIO CARDS INFORMATIVAS */
.cardsHolder,
.cardsContainer,
.cardsIcon,
.cardsText,
.cardsData {
  display: flex;
}

@media screen and (max-width: 991px) {
  .cardsHolder {
    display: none !important;
  }
}
.cardsHolder {
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.cardsContainer {
  border: solid 1px #dadada;
  border-radius: 5px;
  margin: 4px;
  cursor: pointer;
  color: white;
  transition: all 0.15s ease-in-out;
  background: grey;
}

.cardsIcon,
.cardsText,
.cardsData {
  padding: 15px;
  border-color: #F0F0F0;
  border-width: 1px;
  align-items: center;
}

.cardsIcon {
  padding-right: 0;
}

.cardsIcon img {
  width: auto;
  max-height: 70%;
  transition: all 0.15s ease-in-out;
}

.mat-dialog-container .cardsHolder .cardsIcon,
.mat-dialog-container .cardsHolder .cardsText,
.mat-dialog-container .cardsHolder .cardsData {
  padding: 10px;
}

.cardsData {
  font-size: 1.5rem;
  background: rgba(255, 255, 255, 0.15);
  justify-content: center;
  min-width: 40px;
}

.badgeBgAlert,
.cardBgAlert {
  background: #ea8c00;
}

.cardBgDanger {
  background: #d83040;
}

.cardBgOk {
  background: #3aab00;
}

.cardBgInfo {
  background: #2199e8;
}

.cardBgViolet {
  background: #3f51b5;
}

.cardBgDone {
  background: #6c757d;
}

.cardIcon {
  color: white;
}

/* inicio cards que ocupan todo el espacio */
/* estos cards toman todo el ancho del contenedor, para no dejar espacios vacios, se agrega al contenedor .cardsContainer */
.cardsContainerFullWidth {
  flex-grow: 2;
  justify-content: space-between;
}

/* final cards que ocupan todo el espacio */
/* inicio cards sin data "cardData" */
.cardsContainerNoData {
  justify-content: flex-start;
  cursor: default;
}

.cardsContainerNoData .cardsText {
  flex-direction: column;
  align-items: flex-start;
}

.cardTextTitle {
  font-size: 11px;
  color: #F0F0F0;
}

.cardTextData {
  font-size: 16px;
}

/* final cards sin data "cardData" */
/* inicio cards clickeables */
.cardClickeable {
  cursor: pointer;
}

.cardClickeable:hover {
  filter: brightness(1.05);
}

.cardClickeable:hover .cardsIcon img {
  transform: scale(1.1);
}

/* final cards clickeables */
.cardsGenericTheme {
  background: white;
  color: black;
  border: none;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.cardsGenericTheme .cardTextTitle {
  color: grey;
}

/* fix para que los margenes sean iguales cuando los cards ocupan 100% de ancho */
.cardsFixMarginsContainer {
  margin-right: -10px;
}

.cardsFixMargins {
  margin: 10px 10px 0 0;
}

/* FINAL CARDS INFORMATIVAS */
/* INICIO LAYOUT FIXED */
.layoutFixedBottom {
  display: flex;
  justify-content: space-between;
}

.layoutFixedBottomLeft,
.layoutFixedBottomMiddle,
.layoutFixedBottomRight {
  flex-basis: 100%;
  padding: 0.1rem;
}

.layoutFixedBottomLeft {
  margin-right: 16px;
}

.layoutFixedBottom {
  max-height: calc(100vh - 270px);
  overflow-y: auto;
}

@media screen and (max-width: 1100px) {
  .layoutFixedBottom {
    flex-direction: column;
  }

  .layoutFixedBottomLeft {
    margin-right: 0;
    max-width: 100%;
    margin-bottom: 8px;
  }

  .layoutFixedBottom {
    max-height: 100%;
  }
}
/* FINAL LAYOUT FIXED */
/* INICIO presentacion de campos, no editables, solo texto */
.dataGroupContainer {
  margin: 0 25px 5px 25px;
  display: flex;
  flex-wrap: wrap;
}

.dataGroup {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding-right: 1rem;
  box-sizing: border-box;
}

.dataTag {
  font-size: 14px;
  margin-bottom: 2px;
  color: grey;
}

.dataValue {
  font-size: 16px;
}

.dataValueLink {
  text-decoration: none;
  color: #0058A4;
  font-size: 12px;
}

.dataValueLink:hover {
  text-decoration: underline;
}

.dataValueLink mat-icon {
  transform: scale(0.75) translate(-3px, -2px);
  vertical-align: top;
  color: grey;
}

.dataValueLink:hover mat-icon {
  color: inherit;
}

/* FINAL presentacion de campos, no editables, solo texto */
.cellLink-noStyle {
  text-decoration: none;
  color: #0058A4;
  font-weight: 600;
}

.cellLink-noStyle:hover {
  text-decoration: none;
}

/*
INICIO layout 1:
  - header con botones
  - tabla
*/
.headerWithActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f7f7f7;
  padding: 5px 15px 5px 15px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 3px;
  border: solid 1px #e2e2e2;
}

.headerTitle {
  font-size: 17px;
  white-space: nowrap;
}

.headerInput {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 10px 0 10px;
}

.headerActions {
  white-space: nowrap;
}

.headerSearchContainer {
  flex-basis: 100%;
  padding: 15px 15px 15px 100px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.headerSearchContainer mat-form-field {
  width: 100%;
  max-width: 500px;
}

.headerActions button:last-child {
  margin-right: 0;
}

.tablaScrollFullHeight {
  overflow: auto;
  max-height: calc(100vh - 200px) !important;
}

.headerMobileInline {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 992px) {
  .headerWithActionsResponsive {
    flex-direction: column;
    padding: 15px;
  }

  .headerTitle,
.headerInput {
    width: 100%;
  }

  .mobileAlignStart {
    align-self: flex-start;
  }

  .mobileAlignEnd {
    align-self: flex-end;
  }

  .headerActions {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  .headerSearchContainer {
    width: 100%;
    padding: 0;
  }

  .headerSearchContainer mat-form-field {
    max-width: 100%;
  }

  .headerTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
/* FINAL layout 1 */
.mat-table {
  border-radius: 0 !important;
  margin-top: 0 !important;
}

/* INICIO layout para pantallas que contienen tabla de datos, botones y selector de sucursal en la parte superior */
.newLayoutContainer {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
}

.newLayoutCentro {
  flex-basis: auto;
  height: auto;
  overflow-x: auto;
}

.newLayoutCentro table {
  width: 100%;
}

.newLayoutFilters {
  /* contenedor de filtros */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* FINAL layout para pantallas que contienen tabla de datos, botones y selector de sucursal en la parte superior */
.csv-upload-example {
  border: solid 1px #d8d8d8;
  border-radius: 0.6em;
  background: #f7f7f7;
}
.csv-upload-example table {
  text-align: left;
  padding: 10px;
  width: 100%;
  border: solid 1px #d8d8d8;
  border-radius: 0.6em;
  background: #f7f7f7;
  font-style: italic;
  color: #4e4e4e;
}
.csv-upload-example table td,
.csv-upload-example table th {
  padding-right: 10px;
}
.csv-upload-example table td::after,
.csv-upload-example table th::after {
  content: ";";
}

.loading-child-component {
  display: block;
  justify-content: center;
  position: absolute;
}

.child-card {
  height: 2px;
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}

span.pill-warn {
  background-color: #f44336;
  color: #fff;
  padding: 5px;
  line-height: 15px;
  height: fit-content;
  margin-left: 10px;
  margin-top: 8px;
  border-radius: 15px;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.mat-hint {
  font-size: 12px;
}

.scan-dialog-container .mat-dialog-container {
  outline: 2px solid greenyellow;
  padding: 0px !important;
  overflow: hidden;
}

@media screen and (max-width: 991px) {
  .ocultarEnMobile {
    display: none;
    visibility: hidden;
  }
}
.data-valdiation-landing-corner-class .mat-dialog-container {
  border-radius: 20px !important;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.safe-letter {
  color: #5ac73c;
}

.warning-letter {
  color: #bd9f32;
}

.danger-letter {
  color: #b82b35;
}